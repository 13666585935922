import { signIn, useSession } from 'next-auth/react';
import LoadingPage from '@/components/atoms/loading-page';
import { setUserInSessionStorage } from '@/helpers/auth';
import { TOKEN_REFRESH_ERROR } from '@/helpers/auth/constants';
import { dispatchToGTM, USER_IDENTIFIED_EVENT_NAME } from '@/myphoenix/utils/event-functions';
import type { ReactElement } from 'react';

function AuthRedirect({ children }: { children : ReactElement }) {
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated: () => signIn('cognito'),
  });

  if (session?.error === TOKEN_REFRESH_ERROR) {
    setUserInSessionStorage({});
    signIn('cognito');
  }

  if (status === 'loading') {
    return <LoadingPage />;
  }

  if (status === 'authenticated') {
    setUserInSessionStorage(session?.user);
    dispatchToGTM(USER_IDENTIFIED_EVENT_NAME, {
      user: {
        personId: session?.user?.personId,
      },
    });
    return children;
  }
}

export default AuthRedirect;
